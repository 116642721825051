import {
  createContext,
  useContext,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import { Socket, io } from "socket.io-client";
import { getSocketUrl } from "@/env";
import Cookies from "js-cookie";
import { useQueryState } from "@/hooks/useQueryState";
import { useQueryClient } from "@tanstack/react-query";
import { contract } from "contract";
import { useRouter } from "next/router";

export interface ChatContextValues {
  roomId: string;
}

export interface ChatContextType {
  chatDetails: ChatContextValues | null;
  setChatDetails: (values: ChatContextValues | null) => void;
  activeRoomIsTyping: boolean;
  setActiveRoomIsTyping: Dispatch<SetStateAction<boolean>>;
  socket: Socket | null;
  mutateMessagesApi: () => void;
}

export const ChatContext = createContext<ChatContextType>({
  chatDetails: null,
  setChatDetails: (value) => {},
  activeRoomIsTyping: false,
  setActiveRoomIsTyping: (value) => {},
  socket: null,
  mutateMessagesApi: () => {},
});

export const ChatDataProivder = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [chatDetails, setChatDetails] = useQueryState<ChatContextValues | null>(
    "chatDetails",
    null
  );
  const [socket, setSocket] = useState<Socket | null>(null);
  const [activeRoomIsTyping, setActiveRoomIsTyping] = useState<boolean>(false);
  const mutateQueryClient = useQueryClient();
  const mutateMessagesApi = () => {
    mutateQueryClient.invalidateQueries({
      queryKey: [contract.chat.getMessages.path],
    });
    mutateQueryClient.invalidateQueries({
      queryKey: [contract.chat.getRooms.path],
    });
    mutateQueryClient.invalidateQueries({
      queryKey: [contract.chat.getRoomInfo.path],
    });
    mutateQueryClient.invalidateQueries([
      contract.chat.getUnreadChatCount.path,
    ]);
  };
  useEffect(() => {
    const userToken = Cookies.get("connect.sid");

    const socket = io(getSocketUrl(), {
      transports: ["websocket"],
      auth: {
        token: userToken ?? "",
      },
    });

    socket.on("connect", () => {
      console.log("socket connected");
    });

    socket.on("disconnect", () => {
      console.log("socket disconnected");
    });
    socket.on("connect_error", (err) => {
      console.error(`Connect error due to: ${err.message}`);
    });
    socket.io.on("reconnect_attempt", (e) => {
      console.error(`Reconnect attempt message: ${e}`);
    });
    socket.io.on("reconnect", (e) => {
      console.error(`Reconnect message: ${e}`);
    });

    setSocket(socket);

    return () => {
      socket.off("connect");
      socket.off("disconnect");
      socket.off("connect_error");
      socket.io.off("reconnect_attempt");
      socket.io.off("reconnect");

      socket.disconnect();
      setSocket(null);
    };
  }, []);

  return (
    <ChatContext.Provider
      value={{
        chatDetails,
        setChatDetails,
        activeRoomIsTyping,
        setActiveRoomIsTyping,
        socket,
        mutateMessagesApi,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const ChatProvider = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const path = router.asPath;
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  useEffect(() => {
    if (Cookies.get("connect.sid")) {
      setIsLoggedIn(true);
      return;
    }
    setIsLoggedIn(false);
  }, [path, router.isReady]);

  if (!isLoggedIn) {
    return <>{children}</>;
  }
  return <ChatDataProivder>{children}</ChatDataProivder>;
};

export const useChatData = () => useContext(ChatContext);
