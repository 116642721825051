import { useRouter } from "next/router";
import { createContext, useContext } from "react";
import { useLogin } from "@/contexts/LoginProvider";
import { UserDataType } from "@/types/auth";
import { contract } from "contract";
import { Center, Skeleton } from "@mantine/core";
import { getQueryClient } from "@/api";
import ErrorMessage from "@/components/ErrorMessage";
import CustomErrorMessage from "@/components/CustomErrorMessage";
import usePosthogTracking from "@/hooks/usePosthogTracking";
import isNil from "lodash/isNil";

interface ContextInterface {
  userDetails: UserDataType;
}

const UserDataContext = createContext<ContextInterface>({
  userDetails: { currentProject: { id: "" } } as UserDataType,
});

export const UserProvider = ({
  children,
  loader,
}: {
  children: React.ReactNode;
  loader?: React.ReactNode;
}) => {
  const { isLoggedIn } = useLogin();
  const { data, isLoading, error } = getQueryClient().user.getProfile.useQuery(
    [contract.user.getProfile.path],
    {}
  );

  usePosthogTracking({
    isLoggedIn,
    userDetails:
      isLoading || !isNil(error) || !data || data.status !== 200
        ? null
        : data.body,
  });
  if (isLoading && !isNil(loader)) {
    return loader;
  }
  if (isLoading && isNil(loader)) {
    return (
      <Center h="100vh" p={{ base: 10, xs: 20, sm: 48 }}>
        <Skeleton h="100%" w="100%" style={{ borderRadius: "20px" }} />
      </Center>
    );
  }
  if (error) {
    return <ErrorMessage error={error} />;
  }
  if (data?.status !== 200) {
    return <CustomErrorMessage errorMessage="User data not available" />;
  }

  return (
    <UserDataContext.Provider
      value={{
        userDetails: data.body,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};
export const UserDataProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const router = useRouter();
  const { isLoggedIn } = useLogin();

  if (
    !isLoggedIn ||
    router.asPath.includes("/register") ||
    router.asPath.includes("/login") ||
    router.asPath.includes("/invite") ||
    router.asPath.includes("/pro-planner-invite") ||
    router.pathname === "/blog/[id]/[slug]" || // As this page is server side rendered, moving UserProvider to BlogsPageHeader
    router.pathname === "/"
  ) {
    return <> {children}</>;
  }

  return <UserProvider>{children}</UserProvider>;
};

export const useUserData = () => useContext(UserDataContext);
