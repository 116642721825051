import { notifications, NotificationData } from "@mantine/notifications";
import { Center, Group, Text } from "@mantine/core";
import {
  IconAlertCircle,
  IconCircleCheck,
  IconCircleX,
  IconInfoCircle,
} from "@tabler/icons-react";
import classes from "@/styles/notification.module.css";

const toastId = "toast-id";
export enum ToastStatus {
  info = "info",
  warning = "warning",
  success = "success",
  error = "error",
}
export const getStyles = (status: ToastStatus) => {
  switch (status) {
    case ToastStatus.info:
      return {
        bg: "blue",
      };
    case ToastStatus.warning:
      return {
        bg: "orange",
      };
    case ToastStatus.success:
      return {
        bg: "#009379",
      };
    case ToastStatus.error:
      return {
        bg: "red",
      };
  }
};
export const getIcon = (status: ToastStatus) => {
  switch (status) {
    case ToastStatus.info:
      return <IconInfoCircle color="white" size={28} />;
    case ToastStatus.warning:
      return <IconAlertCircle color="white" size={28} />;
    case ToastStatus.success:
      return <IconCircleCheck color="white" size={28} />;
    case ToastStatus.error:
      return <IconCircleX color="white" size={28} />;
  }
};
export const useCustomToast = () => {
  const showToast = ({
    status = ToastStatus.success,
    ...props
  }: {
    status: ToastStatus;
  } & NotificationData) => {
    notifications.show({
      id: toastId,
      withCloseButton: true,
      autoClose: 2000,
      loading: false,
      style: { color: "white" },
      classNames: classes,
      color: "transparent",
      radius: "12px",
      ...getStyles(status),
      ...props,
      message: (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 11fr",
            gap: "10px",
            alignItems: "center",
          }}
        >
          {getIcon(status)}
          <Text
            ff="inter"
            fz={16}
            fw={500}
            lh="1.21"
            c="white"
            style={{ userSelect: "none", fontWeight: 500 }}
            mih={20}
          >
            {props.message}
          </Text>
        </div>
      ),
    });
  };

  return { showToast };
};
