const publicRuntimeConfig =
  require("next/config").default().publicRuntimeConfig;

const {
  apiUrl,
  env,
  socketUrl,
  backendUrl,
  frontendUrl,
  s3BucketUrl,
  postHogApiKey,
  projectId,
} = publicRuntimeConfig;
export const getApiUrl = () => {
  return apiUrl;
};

export const getEnv = () => {
  return env;
};

export const getProjectId = () => {
  return projectId;
};

export const getSocketUrl = () => {
  return socketUrl;
};

export const getFrontendUrl = () => {
  return frontendUrl;
};

export const getBackendUrl = () => {
  return backendUrl;
};
export const getS3BucketUrl = () => {
  return s3BucketUrl;
};

export const getPostHogApiKey = () => {
  return postHogApiKey;
};
