import { z } from "zod";

export function createPaginatedResponseSchemaAlbum<
  ItemType extends z.ZodTypeAny,
>(itemSchema: ItemType) {
  return z.object({
    currentPageNumber: z.number(),
    currentPageSize: z.number(),
    totalItems: z.number(),
    totalPages: z.number(),
    results: z.array(itemSchema),
  });
}

export function createPaginatedResponseSchemaMedia<
  ItemType extends z.ZodTypeAny,
>(itemSchema: ItemType) {
  return z.object({
    currentPageNumber: z.number(),
    currentPageSize: z.number(),
    totalItems: z.number(),
    totalPages: z.number(),
    results: itemSchema,
  });
}

export function createPaginatedResponseSchema<ItemType extends z.ZodTypeAny>(
  itemSchema: ItemType
) {
  return z.object({
    currentPageNumber: z.number(),
    currentPageSize: z.number(),
    totalItems: z.number(),
    totalPages: z.number(),
    results: z.array(itemSchema),
  });
}

export const stringToDateConvertor = (
  dateString: string,
  ctx: z.RefinementCtx
) => {
  const date = new Date(dateString);
  if (!z.date().safeParse(date).success) {
    ctx.addIssue({
      code: z.ZodIssueCode.invalid_date,
    });
  }
  return date;
};

export const formatMoney = (value: number) => {
  // const formatter = new Intl.NumberFormat("en-US");
  // return formatter.format(value);
  //TODO : Alter to suit the currency format client wants in future
  return value;
};

export function calculatePercentage(numerator: number, denominator: number) {
  // Check if denominator is zero
  if (denominator === 0) {
    return 0;
  }

  // Calculate percentage
  const percentage = (numerator / denominator) * 100;

  return percentage;
}

export const monthNameToIndex = (monthName: string): number => {
  const months = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ];
  const index = months.indexOf(monthName.toLowerCase());
  return index; // Note: returns -1 if monthName is not found
};

export const passwordValidationChecker = (
  v: {
    newPassword: string;
    repeatedPassword: string;
  },
  ctx: z.RefinementCtx
) => {
  if (v.newPassword !== v.repeatedPassword) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Confirm password should be same as new password.",
    });
  }

  if (v.newPassword.length < 8) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Password should be more than 8 characters.",
    });
  }

  if (v.newPassword.length > 30) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Password should be less than 30 characters.",
    });
  }
};
