import { Text, Divider, Stack, Button } from "@mantine/core";
import { useChatData } from "@/contexts/ChatProvider";
import Link from "next/link";
import isNil from "lodash/isNil";
import { useState, useEffect } from "react";
import { modals } from "@mantine/modals";
import { pageRedirectionModalId } from "@/data/modal";

const ModalChild = ({
  redirectToPage,
  redirectionLink,
}: {
  redirectToPage: string;
  redirectionLink: string;
}) => {
  const [seconds, setSeconds] = useState<number>(5);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(intervalId);
          window.location.href = redirectionLink;
          return prevSeconds;
        }
        return prevSeconds - 1;
      });
    }, 1000);

    return () => clearTimeout(intervalId);
  }, [seconds, redirectionLink]);

  return (
    <Stack justify="center" gap={20}>
      <Divider h="0.5px" style={{ bg: "#BCBCBC" }} />
      <Text c="black" w="100%" ff="Inter" fz={14} fw={600} lh="1.28">
        The current wedding project has been deleted by the project owner!
      </Text>
      <Text c="black" w="100%" ff="Inter" fz={14} fw={400} lh="1.28">
        You will be redirected to {redirectToPage} page in{" "}
        <Text c="#F28482" span>
          {seconds}
        </Text>{" "}
        seconds
      </Text>
      <Link href={redirectionLink}>
        <Button
          w="100%"
          style={{ borderRadius: "50px" }}
          color="var(--mantine-color-primaryGreen-3)"
          fz={{ base: 14, sm: 16, md: 18,  }}
          fw={500}
          lh="1.48"
          ff="Inter"
          variant="filled"
          onClick={() => modals.close(pageRedirectionModalId)}
        >
          Redirect now
        </Button>
      </Link>
    </Stack>
  );
};

const AccountDeletionModalContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { socket } = useChatData();

  const pageRedirectionModal = ({
    redirectToPage,
    redirectionLink,
  }: {
    redirectToPage: string;
    redirectionLink: string;
  }) => {
    return modals.open({
      modalId: pageRedirectionModalId,
      centered: true,
      styles: { content: { padding: 10, borderRadius: 8 } },
      title: (
        <Text ff="Inter" fw="500" lh="1.21" c="#000000" fz={25}>
          Project deleted
        </Text>
      ),
      children: (
        <ModalChild
          redirectToPage={redirectToPage}
          redirectionLink={redirectionLink}
        />
      ),
    });
  };

  useEffect(() => {
    if (!socket) {
      return;
    }
    socket.on("projectGotDeleted", (data) => {
      if (isNil(data.switchingProjectId)) {
        pageRedirectionModal({
          redirectToPage: "create wedding",
          redirectionLink: "/create-wedding/?pathChosen=false",
        });
      } else {
        pageRedirectionModal({
          redirectToPage: "profile",
          redirectionLink: "/profile",
        });
      }
    });
    return () => {
      socket.off("projectGotDeleted");
    };
  }, [socket]);

  return <>{children}</>;
};
export default AccountDeletionModalContainer;
