import { initContract } from "@ts-rest/core";
import { SuccessSchema } from "contract/common";
import {
  ChangePasswordSchema,
  EmailLoginResponse,
  EmailLoginSchema,
  EmailRegisterResponse,
  EmailregisterSchema,
  GetCityResponse,
  GetForgetPasswordResponse,
  SendForgetPasswordEmail,
  SetPasswordSchema,
  forgetPasswordSchema,
} from "contract/auth/types";
import { z } from "zod";

const c = initContract();

export const authContract = c.router(
  {
    emailLogin: {
      method: "POST",
      path: "/login",
      responses: {
        200: EmailLoginResponse,
      },
      body: EmailLoginSchema,
    },
    register: {
      method: "POST",
      path: "/register",
      responses: {
        200: EmailRegisterResponse,
      },
      body: EmailregisterSchema,
    },
    getCities: {
      method: "GET",
      path: "/city",
      responses: {
        200: GetCityResponse,
      },
      query: z.object({
        cityName: z.string().optional(),
        pageSize: z.coerce.number().optional(),
      }),
    },
    changePassword: {
      method: "POST",
      path: "/changePassword",
      responses: {
        200: SuccessSchema,
      },
      body: ChangePasswordSchema,
    },
    setPassword: {
      method: "POST",
      path: "/setPassword",
      responses: {
        200: SuccessSchema,
      },
      body: SetPasswordSchema,
    },
    sendForgetPasswordEmail: {
      method: "POST",
      path: "/sendForgetPasswordEmail",
      responses: {
        200: SuccessSchema,
      },
      body: SendForgetPasswordEmail,
    },
    forgetPassword: {
      method: "POST",
      path: "/forgetPassword",
      responses: {
        200: SuccessSchema,
      },
      query: z.object({
        forgetPasswordId: z.string(),
      }),
      body: forgetPasswordSchema,
    },
    getForgetPasswordInfo: {
      method: "GET",
      path: "/forgetPassword",
      responses: {
        200: GetForgetPasswordResponse,
      },
      query: z.object({
        forgetPasswordId: z.string(),
      }),
    },
    logout: {
      method: "POST",
      path: "/logout",
      body: z.object({}),
      responses: {
        200: SuccessSchema,
      },
    },
    getGoogleRegisterInfo: {
      method: "GET",
      path: "/googleRegister",
      query: z.object({
        googleUserId: z.string(),
      }),
      responses: {
        200: z.object({
          email: z.string(),
          firstName: z.string(),
          lastName: z.string(),
          picture: z.string().nullable(),
        }),
      },
    },
  },
  {
    pathPrefix: "/auth",
  }
);
