import { initContract } from "@ts-rest/core";
import {
  ConvertingDontKnowMyWeddingDateToKnowMyWeddingDateRequest,
  GetProjectMemberListResponse,
  GetWeddingProjectInfoResponse,
  InviteMemberInfoResponse,
  createWeddingRequest,
  createWeddingResponse,
  inviteMemberConfirmRequest,
  inviteMemberConfirmResponse,
  inviteMemberRequest,
} from "./types";
import { SuccessSchema } from "contract/common";
import { z } from "zod";
import {
  createPaginatedResponseSchema,
  stringToDateConvertor,
} from "contract/utils";
import { UserRoleEnum } from "contract/enum";

const c = initContract();

export const weddingContract = c.router(
  {
    addWedding: {
      method: "POST",
      path: "/",
      responses: {
        200: createWeddingResponse,
      },
      body: createWeddingRequest,
    },
    addWeddingDate: {
      method: "POST",
      path: "/date",
      responses: {
        200: SuccessSchema,
      },
      body: z.object({
        weddingId: z.string(),
        weddingDate: z.string().transform(stringToDateConvertor),
      }),
    },
    inviteMember: {
      method: "POST",
      path: "/member/invite",
      responses: {
        200: SuccessSchema,
      },
      body: inviteMemberRequest,
    },
    inviteMemberConfirm: {
      method: "POST",
      path: "/member/invite/confirm",
      responses: {
        200: inviteMemberConfirmResponse,
      },
      body: inviteMemberConfirmRequest,
    },
    searchUsersToInvite: {
      method: "GET",
      path: "/user/search",
      query: z.object({
        searchText: z.string(),
        projectId: z.string(),
      }),
      responses: {
        200: z.array(
          z.object({
            id: z.string(),
            name: z.string(),
            email: z.string(),
            profilePhotoUrl: z.string().nullable(),
            isProPlanner: z.boolean(),
          })
        ),
      },
    },
    getInvitedUserData: {
      method: "GET",
      path: "/member/invite",
      responses: {
        200: InviteMemberInfoResponse,
      },
      query: z.object({
        inviteId: z.string(),
      }),
    },
    getProjectMemberList: {
      method: "GET",
      path: "/member",
      responses: {
        200: createPaginatedResponseSchema(GetProjectMemberListResponse),
      },
      query: z.object({
        isWeddingDateTask: z
          .enum(["true", "false"])
          .transform((value) => value === "true"),
        projectId: z.string(),
        searchText: z.string().optional(),
        pageSize: z.coerce.number().min(1),
        pageNumber: z.coerce.number().min(1),
      }),
    },
    getInvitedMembesList: {
      method: "GET",
      path: "/member/invitedMembers",
      responses: {
        200: createPaginatedResponseSchema(
          z.object({
            id: z.string(),
            email: z.string(),
            userRole: z.nativeEnum(UserRoleEnum),
            invitedTime: z.date(),
          })
        ),
      },
      query: z.object({
        projectId: z.string(),
        pageSize: z.coerce.number().min(1),
        pageNumber: z.coerce.number().min(1),
      }),
    },
    getWeddingProjectInfo: {
      method: "GET",
      path: "/",
      responses: {
        200: GetWeddingProjectInfoResponse,
      },
      query: z.object({
        projectId: z.string(),
      }),
    },
    addKeyMomentsSeenModal: {
      method: "PATCH",
      path: "/addKeyMomentsSeenModal",
      responses: {
        200: SuccessSchema,
      },
      body: z.object({
        projectId: z.string(),
      }),
    },
    convertingDontKnowMyWeddingDateToKnowMyWeddingDate: {
      method: "POST",
      path: "/convertDontKnowMyWeddingDateToKnowMyWeddingDate",
      responses: {
        200: SuccessSchema,
      },
      body: ConvertingDontKnowMyWeddingDateToKnowMyWeddingDateRequest,
    },
    editWeddingDate: {
      method: "PATCH",
      path: "/editWeddingDate",
      responses: {
        200: SuccessSchema,
      },
      body: z.object({
        projectId: z.string(),
        weddingDate: z.string().transform(stringToDateConvertor),
      }),
    },
  },
  { pathPrefix: "/wedding" }
);
