import {
  Month,
  SpecialCategoryType,
  SpecialTaskType,
  TimelineType,
  TimelineView,
  UserRoleEnum,
} from "contract/enum";
import { stringToDateConvertor } from "contract/utils";
import {
  GetVendorInviteResponse,
  GetVendorOptionalRequest,
} from "contract/vendor/types";
import { boolean, string, z } from "zod";
export const CreateNewTaskRequest = z.object({
  taskName: z.string(),
  timelineId: z.string(),
  taskCategoryId: z.string().optional(),
  assignedProjectMemberId: z.string().optional(),
  assignedDate: z.string().transform(stringToDateConvertor),
  calenderColorData: z.string().optional(),
  coverPhotoURL: z.string().optional(),
});

export const CreateNewTaskCategoryRequest = z.object({
  timelineId: z.string(),
  taskCategoryName: z.string(),
  coverPhotoURL: z.string().optional(),
});

export const CreateNewTimelineRequest = z.object({
  projectId: z.string(),
  timelineType: z.nativeEnum(TimelineType),
});

export const CreateNewTimelineResponse = z.object({
  id: z.string(),
  timelineName: z.string(),
});

export const GetTimelineDataRequest = z
  .object({
    projectId: z.string().optional(),
    timelineType: z.nativeEnum(TimelineType).optional(),
    timelineView: z.nativeEnum(TimelineView),
    searchText: z.string().optional(),
    isArchived: z
      .enum(["true", "false"])
      .transform((value) => value === "true"),
  })
  .refine((value) => {
    if (value.isArchived && !value.timelineType) {
      return false;
    }

    return true;
  }, "Archived timeline should always have a type");

export const TimelineTask = z.object({
  id: z.string(),
  position: z.number(),
  taskName: z.string(),
  specialTaskType: z.nativeEnum(SpecialTaskType).nullable(),
  assignedProjectMember: z
    .object({
      username: z.string(),
      imageURL: z.string().nullable(),
      id: z.string(),
      role: z.nativeEnum(UserRoleEnum),
      email: z.string(),
    })
    .nullable(),
  assignedDate: z.date(),
  isCompleted: z.boolean(),
});

const TimelineMonthTask = z.object({
  monthName: z.nativeEnum(Month),
  tasks: z.array(TimelineTask),
});

const GetMonthTimelineView = z.object({
  timelineName: z.string(),
  id: z.string(),
  isMonthlyView: z.literal(true),
  firstTaskDate: z.date().nullable(),
  lastTaskDate: z.date().nullable(),
  tasksByMonth: TimelineMonthTask.array(),
  timelineStartMonth: z.nativeEnum(Month),
  timelineStartYear: z.number(),
});

const TimelineTaskCategory = z.object({
  taskCategoryName: z.string(),
  id: z.string().nullable(),
  specialCategoryType: z.nativeEnum(SpecialCategoryType).nullable(),
  coverPhotoURL: z.string().nullable(),
  position: z.number().nullable(),
  tasks: z.array(TimelineTask),
});

const GetCategoryTimelineView = z.object({
  timelineName: z.string(),
  id: z.string(),
  firstTaskDate: z.date().nullable(),
  lastTaskDate: z.date().nullable(),
  isMonthlyView: z.literal(false),
  timelineStartMonth: z.nativeEnum(Month),
  timelineStartYear: z.number(),
  taskCategories: TimelineTaskCategory.array(),
});

export const GetTimelineDataResponse = z.discriminatedUnion("isMonthlyView", [
  GetCategoryTimelineView,
  GetMonthTimelineView,
]);

export const GetAllTimelineRequest = z.object({
  projectId: z.string(),
});

export const GetAllTimelineResponse = z.object({
  timelines: z.array(
    z.object({
      timelineName: z.string(),
      id: z.string(),
    })
  ),
});

export const GetAllCategoriesRequest = z.object({
  timelineId: z.string(),
});

export const GetAllCategoriesResponse = z.object({
  categories: z.array(
    z.object({
      id: z.string(),
      taskCategoryName: z.string(),
    })
  ),
});

export const MoveTaskRequest = z.object({
  timelineId: z.string(),
  taskId: z.string(),
  taskCategoryId: z.string().nullable(),
  finalMovingPosition: z.coerce.number().min(1),
});

export const EditTaskRequest = z.object({
  taskId: z.string(),
  assignedDate: z.string().transform(stringToDateConvertor).optional(),
  taskName: z.string().optional(),
  isCompleted: z.boolean().optional(),
  assignedProjectMemberId: z.string().optional(),
  calenderColorData: z.string().optional(),
  note: z.string().optional(),
  coverPhotoURL: z.string().optional(),
});

export const EditTaskCategoryRequest = z.object({
  taskCategoryId: z.string(),
  taskCategoryName: z.string().optional(),
  coverPhotoURL: z.string().optional(),
});

export const EditTimelineRequest = z.object({
  timelineId: z.string(),
  timelineName: z.string(),
});

export const DeleteTaskCategoryRequest = z.object({
  taskCategoryId: z.string(),
  moveTaskCategoryid: z.string().nullable(),
});

export const DeleteTaskRequest = z.object({
  taskId: z.string(),
});

export const DeleteTimelineRequest = z.object({
  timelineId: z.string(),
});

export const resetTimelineRequest = z.object({
  projectId: z.string(),
  timelineType: z.nativeEnum(TimelineType).nullable(),
});

export const GetGridTimelineRequest = z.object({
  projectId: z.string(),
  timelineType: z.nativeEnum(TimelineType).optional(),
  gridViewMonthIndex: z.coerce.number().min(1).max(12),
  gridViewYear: z.coerce.number().min(1),
});

export const GetIndividualTimelineResponse = z.object({
  id: z.string(),
  taskName: z.string(),
  calenderColorData: z.string().nullable(),
  specialTaskType: z.nativeEnum(SpecialTaskType).nullable(),
  vendorMeeting: GetVendorOptionalRequest.nullable(),
  assignedDate: z.date(),
  isCompleted: z.boolean(),
});

export const GetGridTimelineResponse = z.object({
  tasks: z.array(GetIndividualTimelineResponse),
});

export const GetTaskEventDetailRequest = z.object({
  taskId: z.string(),
});

export const GetTaskEventDetailResponse = z.object({
  id: z.string(),
  position: z.number(),
  taskName: z.string(),
  calenderColorData: z.string().nullable(),
  assignedProjectMember: z
    .object({
      username: z.string(),
      imageURL: z.string().nullable(),
      id: z.string(),
      role: z.nativeEnum(UserRoleEnum),
      email: z.string(),
    })
    .nullable(),
  assignedDate: z.date(),
  isCompleted: z.boolean(),
  note: z.string().nullable(),
  isWeddingDateTask: z.boolean(),
});

export const GetCommentsRequest = z.object({
  taskId: z.string(),
  pageNumber: z.coerce.number().min(1),
  pageSize: z.coerce.number().min(1),
});

export const GetCommentsResponse = z.object({
  id: z.string(),
  comment: z.string(),
  createdAt: z.date(),
  createdBy: z.object({
    username: z.string(),
    imageURL: z.string().nullable(),
    id: z.string(),
    role: z.nativeEnum(UserRoleEnum),
    email: z.string(),
  }),
});

export const CreateCommentRequest = z.object({
  taskId: z.string(),
  comment: z.string(),
});

export const DeleteCommentRequest = z.object({
  commentId: z.string(),
});

export const GetKeyMomentsResponse = z.object({
  taskName: z.string(),
  id: z.string(),
  coverPhotoURL: z.string().nullable(),
  taskDueDate: z.date(),
  isSelected: z.boolean(),
});

export const AddKeyMomentsRequest = z.object({
  projectId: z.string(),
  taskName: z.string(),
  taskDueDate: z.string().transform(stringToDateConvertor),
});

export const EditKeyMomentsRequest = z.object({
  keyMomentId: z.string(),
  taskName: z.string().optional(),
  assignedDate: z.string().transform(stringToDateConvertor).optional(),
  isSelected: z.boolean().optional(),
});

//* Types
export type TimelineTaskCategoryType = z.infer<typeof TimelineTaskCategory>;
export type TimelineMonthTaskType = z.infer<typeof TimelineMonthTask>;
export type TimelineCategoryViewType = z.infer<typeof GetCategoryTimelineView>;
export type TimelineMonthlyViewType = z.infer<typeof GetMonthTimelineView>;
export type GetKeyMomentsResponseType = z.infer<typeof GetKeyMomentsResponse>;
export type EditKeyMomentsRequestType = z.infer<typeof EditKeyMomentsRequest>;
