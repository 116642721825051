import { GuestCapacity, PriceRange, RequestStatus } from "contract/enum";
import { stringToDateConvertor } from "contract/utils";
import { z } from "zod";

export const MAX_FILE_SIZE = 10;
export const ALLOWED_FORMATS = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
];
const GalleryResponse = z.array(
  z.object({
    imageURL: z.string(),
  })
);

const ProPlannerVendorCategoryCollection = z.array(z.string());

export const VendorSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  zipCode: z.string(),
  email: z.string().email(),
  phoneNumber: z.string(),
  phoneCode: z.string(),
  companyName: z.string().min(1).max(100),
  aboutCompanyAndMakeItEasierForPlanners: z.string().min(1).max(2000),
  categories: z.array(z.string()),
  password: z.string(),
  cityLocation: z.string(),
  isProPlanner: z.boolean(),
  proPlannerInviteId: z.string().optional(),
});

export const GetVendorRequest = z.object({
  vendorId: z.string(),
});

export const GetService = z.object({
  id: z.string(),
  guestCapacity: z.nativeEnum(GuestCapacity).nullable(),
  pricingDetails: z.string(),
  price: z.number().nullable(),
});

export const AddService = z.object({
  id: z.string().optional(),
  guestCapacity: z.nativeEnum(GuestCapacity).nullable(),
  pricingDetails: z.string(),
  price: z.number().nullable(),
});
export const GetInvitedVendorInfo = z.object({
  email: z.string().email(),
  vendorName: z.string(),
  aboutCompanyAndMakeItEasierForPlanners: z.string(),
  category: z.array(
    z.object({
      id: z.string(),
      categoryName: z.string(),
    })
  ),
  status: z.nativeEnum(RequestStatus).nullable(),
});
export const GetVendorResponse = z.object({
  coverImage: z.string().nullable(),
  companyName: z.string(),
  startingPrice: z.number().nullable(),
  maxPrice: z.number().nullable(),
  aboutCompany: z.string(),
  galleryImages: GalleryResponse.max(5).nullable(),
});
export const GetSubVendorResponse = GetInvitedVendorInfo.extend({
  id: z.string(),
});
export const GetVendorReponse = GetVendorResponse.extend({
  categories: z.array(z.object({ categoryName: z.string(), id: z.string() })),
  location: z.object({ cityName: z.string(), id: z.string() }),
  services: z.array(GetService),
  vendors: z.array(GetSubVendorResponse).nullable(),
  chatRoomId: z.string().nullable(),
  isChatRoomEnabled: z.boolean().nullable(),
});

export const UpdateVendorBody = GetVendorResponse.extend({
  categories: z.array(z.string()).min(1),
  location: z.string(),
  services: z.array(AddService),
  vendors: z.array(GetInvitedVendorInfo.extend({ id: z.string().optional() })),
  isProPlanner: z.boolean(),
});

export const GetAllVendorResponse = z.object({
  vendorName: z.string(),
  id: z.string(),
  coverImage: z.string().nullable(),
  startingPrice: z.number().nullable(),
  maxPrice: z.number().nullable(),
  companyName: z.string(),
  category: z.array(z.string()).nullable(),
  location: z.string(),
});

export const GetAllVendorRequest = z.object({
  pageSize: z.coerce.number().min(1),
  pageNumber: z.coerce.number().min(1),
  location: z.array(z.string()).optional().default([]),
  guestCapacity: z.array(z.nativeEnum(GuestCapacity)).optional().default([]),
  priceRange: z.nativeEnum(PriceRange).optional(),
  category: z.array(z.string()).optional().default([]),
  availableDate: z.string().transform(stringToDateConvertor).optional(),
  searchText: z.string().optional(),
});

export const CreateInviteRequest = z
  .object({
    firstName: z.string(),
    lastName: z.string(),
    email: z.string().email(),
    weddingDate: z.string().transform(stringToDateConvertor),
    isWeddingDateFlexible: z.boolean(),
    numberOfGuest: z.number(),
    phoneNumber: z.string().optional(),
    phoneCode: z.string().optional(),
    meetingDate: z.string().transform(stringToDateConvertor),
    description: z.string().min(1).max(1000).optional(),
    vendorId: z.string(),
  })
  .refine(
    (data) => {
      // Either both phoneNumber and phoneCode are present, or both are absent
      return (
        (data.phoneNumber == null && data.phoneCode == null) ||
        (data.phoneNumber != null && data.phoneCode != null)
      );
    },
    {
      message: "phoneNumber and phoneCode should be provided together.",
      path: ["phoneNumber", "phoneCode"],
    }
  );

export const GetVendorInviteResponse = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  weddingDate: z.date().nullable(),
  isDateFlexible: z.boolean(),
  numberOfGuests: z.number(),
  phoneNumber: z.string().nullable(),
  phoneCode: z.string().nullable(),
  description: z.string().nullable(),
  meetingDate: z.date(),
});
export const GetVendorInviteResponseWithStatus = GetVendorInviteResponse.extend(
  {
    status: z.nativeEnum(RequestStatus),
    rejectionMessage: z.string().nullable(),
  }
);
export const GetVendorOptionalRequest = z.object({
  id: z.string().nullable(),
  firstName: z.string().nullable(),
  lastName: z.string().nullable(),
  email: z.string().nullable(),
  weddingDate: z.date().nullable(),
  isDateFlexible: z.boolean().nullable(),
  numberOfGuests: z.number().nullable(),
  phoneNumber: z.string().nullable(),
  phoneCode: z.string().nullable(),
  description: z.string().nullable(),
  meetingDate: z.date().nullable(),
});
export const VendorCalendarResponse = GetVendorInviteResponse.extend({
  meetingName: z.string(),
  isCompleted: z.boolean(),
});
export type GalleryResponseInterface = z.infer<typeof GalleryResponse>;
export type ProPlannerVendorCategoryCollectionInterface = z.infer<
  typeof ProPlannerVendorCategoryCollection
>;

export const AcceptOrDeclineInviteBody = z
  .object({
    requestId: z.string(),
    status: z.nativeEnum(RequestStatus),
    rejectionMessage: z.string().optional(),
  })

  .refine(
    (data) =>
      data.status !== RequestStatus.rejected ||
      (data.rejectionMessage &&
        data.rejectionMessage.length >= 2 &&
        data.rejectionMessage.length <= 100),
    {
      message: "Rejection message must be between 2 and 100 characters long.",
      path: ["rejectionMessage"],
    }
  );

export const ExcelFileUploadSchema = z.object({
  file: z
    .custom<File>()
    .refine((file) => file instanceof File, {
      message: "Expected a file.",
    })
    .refine(
      (file) => file.size <= MAX_FILE_SIZE,
      `File size should be less than ${MAX_FILE_SIZE}MB.`
    )
    .refine(
      (file) => ALLOWED_FORMATS.includes(file.type),
      "Only .xlsx and .xls files are allowed."
    ),
});

export const FileUploadErrorResponseSchema = z.object({
  errors: z.string().array(),
  kind: z.literal("error"),
});

export const ProPlannerSubVendor = z.object({
  vendorName: z.string(),
  aboutCompanyAndMakeItEasierForPlanners: z.string(),
  categories: z.array(
    z.object({
      id: z.string(),
      categoryName: z.string(),
    })
  ),
  email: z.string().email(),
});
export const BulkUploadSuccessSchema = z.object({
  uploadedData: z.array(ProPlannerSubVendor),
  kind: z.literal("success"),
});
export const BulkUploadVendorSchema = z.object({
  vendorName: z.string().min(1).max(150),
  description: z.string().min(1).max(200),
  email: z.string().email(),
  categories: z
    .string()
    .transform((s) => s.split(",").map(String).filter(Boolean)),
});

export type BulkUploadVendorDto = z.infer<typeof BulkUploadVendorSchema>;
