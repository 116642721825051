import { RoomType, UserChatStatus } from "contract/enum";
import { boolean, z } from "zod";

export const User = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
});

const ReactionMessage = z.object({
  id: z.string(),
  reactionMessage: z.string(),
});

export const Message = z.object({
  id: z.string(),
  content: z.string(),
  reactions: z.array(ReactionMessage),
  isSender: z.boolean(),
  isRead: z.boolean(),
  readTime: z.date().nullable(),
  isDelivered: z.boolean(),
  deliveredTime: z.date().nullable(),
  timeStamp: z.date(),
  sentBy: User,
});

export const CreateRoomResponse = z.object({
  id: z.string(),
  roomName: z.string(),
  users: z.array(
    z.object({
      id: z.string(),
    })
  ),
  roomType: z.nativeEnum(RoomType),
});

export const CreateRoomRequest = z
  .object({
    roomName: z.string().optional(),
    users: z.array(
      z.object({
        id: z.string(),
      })
    ),
    isPrivate: z.boolean(),
  })
  .refine(
    (values) =>
      values.isPrivate === true
        ? values.users.length === 1
        : values.users.length > 1,
    {
      message:
        "private can only have one user and group should have more than 1 user",
      path: ["users"],
    }
  );

export const CreateMessageRequest = z.object({
  roomId: z.string(),
  content: z.string(),
});

export const GetMessageRequestQuery = z.object({
  roomId: z.string(),
  searchText: z.string().optional(),
  pageNumber: z.coerce.number().min(1),
  pageSize: z.coerce.number().min(1),
});

export const getRoomQuery = z.object({
  isUserVendor: z.string().transform((value) => value === "true"),
  userNameSearchText: z.string().optional(),
  pageNumber: z.coerce.number().min(1),
  pageSize: z.coerce.number().min(1),
});

export const GetRoomResponse = z.object({
  roomId: z.string().nullable(),
  roomName: z.string().nullable(),
  roomType: z.nativeEnum(RoomType).nullable(),
  roomTotalUnreadMessages: z.string().nullable(),
  userId: z.string(),
  userEmail: z.string(),
  userFirstName: z.string(),
  userLastName: z.string(),
  userPhotoUrl: z.string().nullable(),
  isTyping: z.boolean().optional(),
  userChatStatus: z.nativeEnum(UserChatStatus),
  latestChat: z.object({
    messageId: z.string().nullable(),
    roomName: z.string().nullable(),
    content: z.string().nullable(),
    sentBy: z.string().nullable(),
  }),
});
export const GetRoomInfoResponse = z.object({
  roomId: z.string(),
  roomName: z.string(),
  user: z.optional(
    z.object({
      userId: z.string(),
      firstName: z.string(),
      lastName: z.string(),
      profilePhotoUrl: z.string().nullable(),
      userChatStatus: z.nativeEnum(UserChatStatus),
      lastOnline: z.date().nullable(),
    })
  ),
});

export const GetRoomInfoRequestQuery = z.object({
  roomId: z.string(),
});

export const GetUsersRequestQuery = z.object({
  userNameSearchText: z.string().optional(),
  pageNumber: z.coerce.number().min(1),
  pageSize: z.coerce.number().min(1),
});

export const GetUsersResponse = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  phoneNumber: z.string().nullable(),
  profilePhotoUrl: z.string().nullable(),
  createdAt: z.date().nullable(),
});
