import { initContract } from "@ts-rest/core";
import {
  AddAlbumRequest,
  AddAlbumResponse,
  AddAllMediaResponse,
  AddMediaRequest,
  AllNewMediaForNewTheme,
  getAlbumsResponse,
  getMediaItemsResponse,
  getMediaItemsQuery,
  getAlbumsQuery,
  EditAlbumNameRequest,
  DeleteAlbumRequest,
  DeleteMediaRequest,
  GetSystemMediaItemResponse,
  GetSystemMediaItemRequest,
  AddSystemMediaItemRequest,
  GetAlbumsForSystemMediaFilesRequest,
  GetAlbumsForSystemMediaFilesResponse,
  GetAlbumsToMoveMediaFilesResponse,
  GetAlbumsToMoveMediaFilesRequest,
  MoveMediaFilesToOtherAlbumsRequest,
  MediaFileUploadSchema,
  MoveSystemMediaFilesToOtherAlbumsRequest,
  GetTypesenseMediaFilesResponse,
  GetTypesenseMediaFilesRequest,
  GetAlbumsToMoveTypeSenseMediaFilesRequest,
  GetAlbumsToMoveTypeSenseMediaFilesResponse,
} from "./types";
import { Multer } from "multer";
import {
  createPaginatedResponseSchema,
  createPaginatedResponseSchemaAlbum,
  createPaginatedResponseSchemaMedia,
} from "contract/utils";
import { SuccessSchema } from "contract/common";
import { GetAllCategoriesRequest } from "contract/timeline/types";

const c = initContract();

export const mediaContract = c.router(
  {
    getAlbums: {
      method: "GET",
      path: "/album",
      responses: {
        200: createPaginatedResponseSchemaAlbum(getAlbumsResponse),
      },
      query: getAlbumsQuery,
    },
    getMediaItems: {
      method: "GET",
      path: "/",
      responses: {
        200: createPaginatedResponseSchemaMedia(getMediaItemsResponse),
      },
      query: getMediaItemsQuery,
    },
    createAlbum: {
      method: "POST",
      path: "/album",
      responses: {
        200: AddAlbumResponse,
      },
      body: AddAlbumRequest,
    },
    createMediaItem: {
      method: "POST",
      path: "/",
      responses: {
        200: AddAllMediaResponse,
      },
      body: AddMediaRequest,
    },
    addNewMediaForTheme: {
      method: "POST",
      path: "/file",
      contentType: "multipart/form-data",
      responses: {
        200: AllNewMediaForNewTheme,
      },
      body: MediaFileUploadSchema,
    },
    renameAlbum: {
      method: "PATCH",
      path: "/album",
      responses: {
        200: SuccessSchema,
      },
      body: EditAlbumNameRequest,
    },
    deleteAlbum: {
      method: "DELETE",
      path: "/album",
      responses: {
        200: SuccessSchema,
      },
      body: DeleteAlbumRequest,
    },
    deleteMedia: {
      method: "DELETE",
      path: "/",
      responses: {
        200: SuccessSchema,
      },
      body: DeleteMediaRequest,
    },
    getSystemMediaFiles: {
      method: "GET",
      path: "/system",
      responses: {
        200: createPaginatedResponseSchema(GetSystemMediaItemResponse),
      },
      query: GetSystemMediaItemRequest,
    },
    addSystemMediaFiles: {
      method: "POST",
      path: "/system",
      responses: {
        200: SuccessSchema,
      },
      body: AddSystemMediaItemRequest,
    },
    getAlbumsForSystemMediaFiles: {
      method: "GET",
      path: "/system/albums",
      responses: {
        200: createPaginatedResponseSchemaMedia(
          GetAlbumsForSystemMediaFilesResponse
        ),
      },
      query: GetAlbumsForSystemMediaFilesRequest,
    },
    getAlbumsToMoveMediaFiles: {
      method: "GET",
      path: "/album/move",
      responses: {
        200: createPaginatedResponseSchema(GetAlbumsToMoveMediaFilesResponse),
      },
      query: GetAlbumsToMoveMediaFilesRequest,
    },
    moveMediaFilesToOtherAlbums: {
      method: "PATCH",
      path: "/album/move",
      responses: {
        200: SuccessSchema,
      },
      body: MoveMediaFilesToOtherAlbumsRequest,
    },
    moveSystemMediaFilesToOtherAlbums: {
      method: "PATCH",
      path: "album/system/move",
      responses: {
        200: SuccessSchema,
      },
      body: MoveSystemMediaFilesToOtherAlbumsRequest,
    },
    searchTypeSenseFiles: {
      method: "GET",
      path: "/typesense",
      responses: {
        200: GetTypesenseMediaFilesResponse,
      },
      query: GetTypesenseMediaFilesRequest,
    },
  },
  {
    pathPrefix: "/media",
  }
);
