import { initContract } from "@ts-rest/core";
import {
  CreateMessageRequest,
  CreateRoomRequest,
  CreateRoomResponse,
  GetMessageRequestQuery,
  GetRoomInfoRequestQuery,
  GetRoomInfoResponse,
  GetRoomResponse,
  GetUsersRequestQuery,
  GetUsersResponse,
  Message,
  User,
  getRoomQuery,
} from "./types";
import { SuccessSchema } from "contract/common";
import { z } from "zod";
import { createPaginatedResponseSchema } from "contract/utils";

const c = initContract();

export const chatContract = c.router(
  {
    createRoom: {
      method: "POST",
      path: "/room",
      responses: {
        200: CreateRoomResponse,
      },
      body: CreateRoomRequest,
    },
    createMessage: {
      method: "POST",
      path: "/message",
      responses: {
        200: SuccessSchema,
      },
      body: CreateMessageRequest,
    },
    getMessages: {
      method: "GET",
      path: "/message",
      responses: {
        200: createPaginatedResponseSchema(Message),
      },
      query: GetMessageRequestQuery,
    },
    getRooms: {
      method: "GET",
      path: "/room",
      responses: {
        200: createPaginatedResponseSchema(GetRoomResponse),
      },
      query: getRoomQuery,
    },
    getRoomInfo: {
      method: "GET",
      path: "/roomInfo",
      responses: {
        200: GetRoomInfoResponse,
      },
      query: GetRoomInfoRequestQuery,
    },
    getUsers: {
      method: "GET",
      path: "/users",
      responses: {
        200: createPaginatedResponseSchema(GetUsersResponse),
      },
      query: GetUsersRequestQuery,
    },
    switchUserAvailability: {
      method: "PATCH",
      path: "/switchUserAvailability",
      responses: {
        200: SuccessSchema,
      },
      body: z.object({
        isUserAvailable: z.boolean(),
      }),
    },
    getUnreadChatCount: {
      method: "GET",
      path: "/getUnreadChatCount",
      responses: {
        200: z.object({
          unreadMessageCount: z.number(),
        }),
      },
    },
  },
  {
    pathPrefix: "/chat",
  }
);
