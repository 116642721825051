import { initContract } from "@ts-rest/core";
import { SuccessSchema } from "contract/common";
import { z } from "zod";

const c = initContract();

export const imageSearchContract = c.router(
  {
    resetDatabase: {
      method: "PUT",
      path: "/resetDatabase",
      body: z.object({
        resetKey: z.string(),
      }),
      responses: {
        200: SuccessSchema,
      },
    },

    populateDB: {
      method: "PUT",
      path: "/populateDB",
      body: z.object({
        resetKey: z.string(),
      }),
      responses: {
        200: SuccessSchema,
      },
    },

    exportToJSON: {
      method: "POST",
      path: "/exportToJSON",
      body: z.object({
        resetKey: z.string(),
      }),
      responses: {
        200: SuccessSchema,
      },
    },
    importFromFile: {
      method: "POST",
      path: "/importFromFile",
      body: z.object({
        resetKey: z.string(),
      }),
      responses: {
        200: SuccessSchema,
      },
    },
  },
  {
    pathPrefix: "/imageSearch",
  }
);
