import { initContract } from "@ts-rest/core";
import { SuccessSchema } from "contract/common";
import { ViewedCoachMarkEnum, editUserInfo, getUserProfile } from "./types";
import { z } from "zod";
import { RequestStatus } from "contract/enum";

const c = initContract();

export const userContract = c.router(
  {
    editProfile: {
      method: "PATCH",
      path: "/",
      responses: {
        200: SuccessSchema,
      },
      body: editUserInfo,
    },
    getProfile: {
      method: "GET",
      path: "/",
      responses: {
        200: getUserProfile,
      },
    },
    setCurrentProject: {
      method: "POST",
      path: "/setCurrentProject",
      responses: {
        200: SuccessSchema,
      },
      body: z.object({
        projectId: z.string(),
      }),
    },
    getUserWeddingProjects: {
      method: "GET",
      path: "/getUserWeddingProjects",
      responses: {
        200: z.object({
          projects: z
            .object({
              id: z.string(),
              name: z.string(),
              isActive: z.boolean(),
            })
            .array(),
        }),
      },
    },
    updateCoachMarkViewed: {
      method: "PUT",
      body: z.object({ type: z.nativeEnum(ViewedCoachMarkEnum) }),
      path: "/updateCoachMarkViewed",
      responses: {
        200: SuccessSchema,
      },
    },
    doesUserHaveAWeddingProject: {
      method: "GET",
      path: "/doesUserHaveAWeddingProject",
      responses: {
        200: z.object({
          hasWeddingProject: z.boolean(),
        }),
      },
    },
    updateEmailNotification: {
      method: "PUT",
      body: z.object({ notificationState: z.boolean() }),
      path: "/updateEmailNotification",
      responses: {
        200: SuccessSchema,
      },
    },
    deleteUser: {
      method: "DELETE",
      body: z
        .object({
          confirmPassword: z.string().optional(),
        })
        .superRefine((v, ctx) => {
          if (v.confirmPassword && v.confirmPassword.length < 8) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Confirm password should be at least 8 characters.",
            });
          }

          if (v.confirmPassword && v.confirmPassword.length > 30) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Confirm password should be less than 30 characters.",
            });
          }
        }),
      path: "/",
      responses: {
        200: SuccessSchema,
      },
    },
    proPlannerInviteAcceptation: {
      method: "POST",
      path: "/pro-planner/invite/accept",
      responses: {
        200: z.object({
          isUserDeleted: z.boolean(),
          isInviteRequestAlreadyAccepted: z.boolean(),
          isProPlannerDeleted: z.boolean(),
        }),
      },
      body: z.object({
        inviteId: z.string(),
      }),
    },
    proPlannerInviteData: {
      method: "GET",
      path: "/pro-planner/invite",
      responses: {
        200: z.object({
          email: z.string().email(),
          proPlannerCompanyName: z.string(),
          proPlannerId: z.string(),
        }),
      },
      query: z.object({
        inviteId: z.string(),
      }),
    },
    proPlannerInviteRequestStatusUpdate: {
      method: "PATCH",
      path: "/pro-planner/invite",
      responses: {
        200: SuccessSchema,
      },
      body: z.object({
        inviteId: z.string(),
        status: z.nativeEnum(RequestStatus),
      }),
    },
  },
  { pathPrefix: "/user" }
);
