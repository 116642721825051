import { Center, Text, TextProps } from "@mantine/core";

const CustomErrorMessage = ({
  errorMessage = "An error occurred",
  ...props
}: {
  errorMessage?: string;
} & TextProps) => {
  return (
    <Center p={10} h="max-content">
      <Text c="white" {...props}>
        {errorMessage}
      </Text>
    </Center>
  );
};
export default CustomErrorMessage;
