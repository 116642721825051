import {
  MantineThemeOverride,
  createTheme,
  mergeThemeOverrides,
} from "@mantine/core";

const breakpointsTheme = createTheme({
  breakpoints: {
    xs: "480px",
    sm: "769px",
    md: "1024px",
    lg: "1184px",
    xl: "1440px",
  },
});
const fontFamily = createTheme({
  fontFamily: "Inter",
});

const colorsTheme = createTheme({
  colors: {
    primaryPink: [
      "#fff9f9", //Color from design [0]
      "#ffd4d3",
      "#F28482", //Color from design [2]
      "#f07573",
      "#eb4c4a",
      "#e8322f",
      "#e82420",
      "#cf1714",
      "#b80f10",
      "#a2000a",
    ],
    secondaryPink: [
      "#ffeeeb",
      "#F5CAC3", //Color from design [1]
      "#efb5ab",
      "#e68c7d",
      "#df6955",
      "#db533c",
      "#da482e",
      "#c23922",
      "#ad311d",
      "#982616",
    ],
    primaryGreen: [
      "#00f5ca",
      "#00e1ba",
      "#00cea9",
      "#17B898", //Color from design [3]
      "#00a789",
      "#009379", //Color from design [5]
      "#007f69",
      "#006c59",
      "#005849",
      "#004538",
      "#003128",
    ],

    secondaryGreen: [
      "#e6f9f9",
      "#deede9",
      "#C4DDD7",
      "#a4beb8",
      "#84A59D", //Color from design [4]
      "#7a9d95",
      "#6f978e",
      "#5d847b",
      "#4e756d",
      "#3c665e",
    ],
    customBlack: [
      "#565673",
      "#4e4e68",
      "#46465d",
      "#3d3d52",
      "#353546",
      "#2c2c3b",
      "#242430", // Color from design [6]
      "#1c1c25",
      "#13131a",
      "#0b0b0e",
      "#020203",
    ],
    customPurple: [
      "#fbfbfd",
      "#eeeef6",
      "#e2e1ef", //Color from design [2]
      "#d6d4e8",
      "#c9c7e1",
      "#b1aed4",
      "#a4a1cd",
      "#9894c6",
      "#8b87bf",
      "#7f7bb8",
      "#736eb2",
    ],
    primaryBeige: [
      "#fefbf5",
      "#fdf3e2",
      "#fcebcf",
      "#fbe4bd",
      "#fadcaa",
      "#f9d498",
      "#f8cc85",
      "#f7c573",
      "#f6bd60", //Color from design [8]
      "#f5b54d",
    ],
    secondaryBeige: [
      "#fbf7f1",
      "#f7ede2", //Color from design [1]
      "#f3e3d3",
      "#efdac3",
      "#ead0b4",
      "#e6c7a5",
      "#e2bd95",
      "#deb486",
      "#d9aa76",
      "#d5a167",
    ],
  },
});

// There are some CSS in global.css that are not included in the theme. TODO - Add them to the theme
export const customTheme: MantineThemeOverride = mergeThemeOverrides(
  breakpointsTheme,
  fontFamily,
  colorsTheme
);
