import { z } from "zod";

export const GetBlogsRequest = z.object({
  pageNumber: z.coerce.number().min(1),
  pageSize: z.coerce.number().min(1),
  blogCategoryCollection: z.array(z.string()).optional(),
  isOrderDesc: z.enum(["true", "false"]).transform((value) => value === "true"),
  slugId: z.string().optional(),
});

export const GetBlogsResponse = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string(),
  blogCategoryCollection: z.array(z.string()),
  slugId: z.string(),
  authorInfo: z.object({
    id: z.string().nullable(),
    name: z.string().nullable(),
    imageFileURL: z.string().nullable(),
  }),
  minutesRead: z.number(),
  imageFileURL: z.string(),
  createdAt: z.date(),
});

export const GetBlogResponse = z.object({
  id: z.string(),
  title: z.string(),
  description: z.string(),
  content: z.string(),
  authorInfo: z
    .object({
      id: z.string().nullable(),
      name: z.string().nullable(),
      imageFileURL: z.string().nullable(),
    })
    .nullable(),
  minutesRead: z.number(),
  imageFileURL: z.string(),
  createdAt: z.date(),
});

export const GetBlogRequest = z.object({
  slug: z.string(),
});
