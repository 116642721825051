export const changePasswordModalId = "changePasswordModalId";
export const setPasswordModalId = "setPasswordModalId";
export const deleteModalId = "deleteModalId";
export const deleteAccountConfirmModalId = "deleteAccountConfirmModalId";
export const deletePermanentlyModalId = "deletePermanentlyModalId";
export const editTaskModalId = "editTaskModalId";
export const pageRedirectionModalId = "pageRedirectionModalId";
export const inviteCollaboratorModalId = "inviteCollaboratorModalId";
export const requestEnquiryModalId = "requestEnquiryModalId";
export const proPlannerRegistrationModalId = "proPlannerRegistrationModalId";
export const vendorRegistrationModalId = "vendorRegistrationModalId";
export const importVendorModal = "importVendorModal";
export const acceptOrRejectProPlannerInviteModalId =
  "acceptOrRejectProPlannerInviteModalId";
export const confirmationModalId = "confirmationModalId";
export const editIndividualVendorModalId = "editIndividualVendorModalId";
export const acceptOrRejectProPlannerJoinRequestModalId =
  "acceptOrRejectProPlannerJoinRequestModalId";
