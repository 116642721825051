import { ProPlannerView, UserRoleEnum, VendorTypeEnum } from "contract/enum";
import { z } from "zod";

export const profilePictureHistoryItem = z.object({
  url: z.string(),
  createdAt: z.coerce.date(),
});

export type ProfilePictureHistoryItem = z.infer<
  typeof profilePictureHistoryItem
>;

export const editUserInfo = z.object({
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  profilePhotoUrl: z.string().optional(),
  phoneNumber: z.string().nullable().optional(),
  profilePictureHistory: z.array(profilePictureHistoryItem),
  phoneCode: z.string().nullable().optional(),
  zipCode: z.string().nullable().optional(),
  isEmailWeddingNotificationEnabled: z.boolean().optional(),
});

export const getUserProfile = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string().email(),
  phoneNumber: z.string().nullable(),
  profilePhotoUrl: z.string().nullable(),
  phoneCode: z.string().nullable(),
  isUserAvailable: z.boolean(),
  profilePictureHistory: z.array(profilePictureHistoryItem),
  currentProjectRole: z.nativeEnum(UserRoleEnum).nullable(),
  vendorType: z.nativeEnum(VendorTypeEnum).nullable(),
  currentProject: z.object({
    id: z.string().nullable(),
    name: z.string(),
    weddingDate: z.date().nullable(),
  }),
  isEmailWeddingNotificationEnabled: z.boolean(),
  isBudgetModalCoachMarkShown: z.boolean(),
  isBudgetCategoryCoachMarkShown: z.boolean(),
  proPlannerView: z.nativeEnum(ProPlannerView).nullable(),
  isPasswordSet: z.boolean(),
  zipCode: z.string().nullable(),
  hasOwnerSeenkeyMomentsSection: z.boolean(),
});

export enum ViewedCoachMarkEnum {
  budgetModal = "budgetModal",
  budgetCategory = "budgetCategory",
}
