import { useEffect } from "react";
import { useRouter } from "next/router";
import { getEnv, getFrontendUrl } from "@/env";
import { UserDataType } from "@/types/auth";
import { PathnameContext } from "next/dist/shared/lib/hooks-client-context.shared-runtime";

const usePosthogTracking = ({
  isLoggedIn,
  userDetails,
}: {
  isLoggedIn: boolean;
  userDetails: UserDataType | null;
}) => {
  const router = useRouter();

  useEffect(() => {
    if (!isLoggedIn || !userDetails) {
      import("posthog-js").then((posthog) => {
        posthog.default.reset();
      });
      return;
    }

    import("posthog-js").then((posthog) => {
      posthog.default.identify(
        userDetails?.email ?? userDetails.firstName, // 'distinct_id'  user's unique identifier
        {
          userDetails: userDetails,
          environment: getEnv(),
          isLoggedIn,
          frontendUrl: getFrontendUrl(),
          pageTemplate: router.pathname,
          actualPageUrl: router.asPath,
          router: {
            pathName: router.pathname, // e.g. themes/[id]
            asPath: router.asPath,
            route: router.route,
            query: router.query,
            basePath: router.basePath,
          },
        } // optional: additional user properties
      );
      posthog.default.capture("$pageview"); // Single page application (SPA) page view event
      // Capture page visit event
      posthog.default.capture("custom_page_view", {
        userDetails: userDetails,
        pageTemplate: router.pathname,
        actualPageUrl: router.asPath,
        router: {
          pathName: router.pathname, // e.g. themes/[id]
          asPath: router.asPath,
          route: router.route,
          query: router.query,
          basePath: router.basePath,
        },
      });
    });
  }, [router.asPath, isLoggedIn, userDetails]);
};

export default usePosthogTracking;
