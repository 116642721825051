import { z } from "zod";

export const GetNotificationType = z.object({
  id: z.string(),
  timestamp: z.date(),
  userPhotoURL: z.string().nullable().optional(),
  username: z.string().optional().nullable(),
  notificationType: z.string(),
  content: z.string(),
  isRead: z.boolean(),
});

export type GetNotificationInterface = z.infer<typeof GetNotificationType>;