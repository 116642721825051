import { initContract } from "@ts-rest/core";
import { SuccessSchema } from "contract/common";
import { z } from "zod";
import { GetNotificationType } from "./types";
import { createPaginatedResponseSchema } from "contract/utils";

const c = initContract();

export const notificationContract = c.router(
  {
    getNotifications: {
      method: "GET",
      path: "/",
      query: z.object({
        // projectId: z.string(),
        pageSize: z.coerce.number().min(1),
        pageNumber: z.coerce.number().min(1),
      }),
      responses: {
        200: createPaginatedResponseSchema(GetNotificationType),
      },
    },
    clearReadNotification: {
      method: "POST",
      path: "/clear",
      body: z.object({
        projectId: z.string().optional(),
      }),
      responses: {
        200: SuccessSchema,
      },
    },
    markNotificationRead: {
      method: "PATCH",
      path: "/",
      body: z.object({
        notificationId: z.string(),
      }),
      responses: {
        200: SuccessSchema,
      },
    },
    totalNotifications: {
      method: "GET",
      path: "/total",
      responses: {
        200: z.object({
          total: z.number(),
        }),
      },
    },
  },
  { pathPrefix: "/notification" }
);
