import { ClientInferResponseBody } from "@ts-rest/core";
import { contract } from "contract";
import {
  EmailLoginSchema,
  EmailregisterSchema,
  forgetPasswordSchema,
} from "contract/auth/types";
import { infer as ZodInfer } from "zod";

export type EmailRegisterInterface = ZodInfer<typeof EmailregisterSchema>;
export type EmailLoginData = ZodInfer<typeof EmailLoginSchema>;

export type CreateUserData = ClientInferResponseBody<
  typeof contract.auth.register
>;
export type LoginUserData = ClientInferResponseBody<
  typeof contract.auth.emailLogin
>;

export type ForgetPasswordType = ZodInfer<typeof forgetPasswordSchema>;

export type UserDataType = ClientInferResponseBody<
  typeof contract.user.getProfile
>;

export const WeddingCreationRouteCookieName = "WeddingCreationRoute";
