import React, { ErrorInfo } from 'react';
import NextError from 'next/error';
import { logRenderError } from '@/api';

// Define the props and state types
interface ErrorBoundaryState {
  hasError: boolean;
}

// Since this component does not receive any special props, you can define it as an empty interface or use React.PropsWithChildren for children props
interface ErrorBoundaryProps extends React.PropsWithChildren<{}> {}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logRenderError(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return (
        <NextError statusCode={500} title="An application error occured" />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
