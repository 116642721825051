import { useMediaQuery } from "@mantine/hooks";
import { ReactNode, createContext, useContext } from "react";

interface ScreenBreakpointsInterface {
  isMobile: boolean | undefined;
  isTablet: boolean | undefined;
  isSmallLaptop: boolean | undefined;
  isLaptop: boolean | undefined;
  isDesktop: boolean | undefined;
  is4k: boolean | undefined;
}
const ScreenBreakpointsContext = createContext<ScreenBreakpointsInterface>({
  isMobile: undefined,
  isTablet: undefined,
  isSmallLaptop: undefined,
  isLaptop: undefined,
  isDesktop: undefined,
  is4k: undefined,
});
export const ScreenBreakpointsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const isMobile = useMediaQuery("(max-width: 480px)");
  const isTablet = useMediaQuery("(min-width: 480px) and (max-width: 768px)");
  const isSmallLaptop = useMediaQuery(
    "(min-width: 768px) and (max-width: 1024px)"
  );
  const isLaptop = useMediaQuery("(min-width: 1024px) and (max-width: 1184px)");
  const isDesktop = useMediaQuery(
    "(min-width: 1184px) and (max-width: 1440px)"
  );
  const is4k = useMediaQuery("(min-width: 1440px)");

  return (
    <ScreenBreakpointsContext.Provider
      value={{
        isMobile,
        isTablet,
        isSmallLaptop,
        isLaptop,
        isDesktop,
        is4k,
      }}
    >
      {children}
    </ScreenBreakpointsContext.Provider>
  );
};
export const useScreenBreakpoints = () => useContext(ScreenBreakpointsContext);
