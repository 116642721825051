import { z } from "zod";

export const AddBudgetTaskRequest = z.object({
  taskCategoryId: z.string(),
  title: z.string(),
  actualCost: z.number().max(10000000),
});

export const UpdateBudgetTaskRequest = z.object({
  taskId: z.string(),
  taskName: z.string().optional(),
  actualCost: z.number().max(10000000).optional(),
});

export const DeleteBudgetTaskRequest = z.object({
  taskId: z.string(),
});

export const UpdateBudgetTaskCategoryRequest = z.object({
  taskCategoryId: z.string(),
  categoryName: z.string(),
});

export const GetTaskCategoryRequest = z.object({
  projectId: z.string().optional(),
});

export const TaskCategoryResponse = z.object({
  id: z.string(),
  categoryName: z.string(),
  imageURL: z.string(),
  allotedCost: z.number(),
  actualCost: z.number(),
});

export const BudgetTaskResponse = z.object({
  id: z.string(),
  taskName: z.string(),
  actualCost: z.number(),
  position: z.number(),
});

export const TaskCategoryWithTasksResponse = z.object({
  id: z.string(),
  categoryName: z.string(),
  imageURL: z.string().nullable(),
  allotedCost: z.number(),
  actualCost: z.number(),
  percentage: z.number(),
  position: z.number(),
  tasks: z.array(BudgetTaskResponse),
});

export const GetTaskCategoryWithTasksResponse = z.object({
  allotedBudget: z.number(),
  taskCategories: z.array(TaskCategoryWithTasksResponse),
  actualTotalCost: z.number(),
});

export const GetTaskRequest = z.object({
  taskCategoryId: z.string(),
});

export const UpdateBudgetRequest = z.object({
  projectId: z.string(),
  taskCategories: z.array(
    z.object({
      id: z.string().nullable(),
      imageURL: z.string().nullable(),
      allotedCost: z.number().max(10000000),
      categoryName: z.string(),
      position: z.number(),
    })
  ),
});

export const moveTaskRequest = z.object({
  taskId: z.string(),
  taskCategoryId: z.string(),
  finalMovingPosition: z.coerce.number().min(1),
});

export type TaskCategoryWithTasksResponseType = z.infer<
  typeof TaskCategoryWithTasksResponse
>;
