import {
  TimelineType,
  UserRoleEnum,
  WeddingCreationRoute,
  WeddingFlowConversionRoute,
} from "contract/enum";
import { stringToDateConvertor } from "contract/utils";
import { z } from "zod";

const User = z.object({
  id: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
});

export const createWeddingRequest = z
  .object({
    name: z.string().optional(),
    weddingDate: z.string().transform(stringToDateConvertor).nullable(),
    coverImage: z.string().optional(),
    brideName: z.string().nullable(),
    groomName: z.string().nullable(),
    partnerEmail: z.string().optional(),
    isUserCreatingAWedding: z.boolean(),
  })
  .refine((wedding) => {
    if (wedding.weddingDate && wedding.weddingDate < new Date()) {
      return false;
    }

    return true;
  }, "Set wedding date should be greater than the present date");

export const createWeddingResponse = z.object({
  id: z.string(),
  name: z.string(),
  weddingDate: z.date().nullable(),
  coverImage: z.string().nullable(),
  createdByUser: User,
  brideName: z.string(),
  groomName: z.string(),
});

export const inviteMemberRequest = z.object({
  projectId: z.string(),
  invitedUsers: z
    .object({
      email: z.string().email(),
      role: z.nativeEnum(UserRoleEnum),
    })
    .array(),
});

export const inviteMemberConfirmResponse = z.object({
  isSuccess: z.boolean(),
  message: z.string(),
  isAlreadyRegistered: z.boolean(),
  inviteId: z.string().nullable(),
  isProjectAvailable: z.boolean(),
  isProPlanner: z.boolean(),
});

export const inviteMemberConfirmRequest = z.object({
  inviteId: z.string(),
});

export const InviteMemberInfoResponse = z.object({
  email: z.string(),
});

export const GetProjectMemberListResponse = z.object({
  id: z.string(),
  userImageURL: z.string().nullable(),
  name: z.string(),
  email: z.string(),
  memberType: z.nativeEnum(UserRoleEnum),
});

export const GetWeddingProjectInfoResponse = z.object({
  id: z.string(),
  weddingName: z.string(),
});

const ArchiveAndStartFresh = z.object({
  projectId: z.string(),
  weddingFlowConversionRoute: z.literal(
    WeddingFlowConversionRoute.archiveAndStartFresh
  ),
  weddingInfo: z.object({
    name: z.string(),
    weddingDate: z.string().transform(stringToDateConvertor).nullable(),
    coverImage: z.string().optional(),
    brideName: z.string().optional(),
    groomName: z.string().optional(),
    partnerEmail: z.string().optional(),
  }),
});

const ManuallyAdjustDate = z.object({
  projectId: z.string(),
  weddingFlowConversionRoute: z.literal(
    WeddingFlowConversionRoute.manuallyAdjustDate
  ),
  weddingInfo: z.object({
    name: z.string(),
    weddingDate: z.string().transform(stringToDateConvertor),
    coverImage: z.string().optional(),
    brideName: z.string().optional(),
    groomName: z.string().optional(),
    partnerEmail: z.string().optional(),
  }),
  timelineType: z.nativeEnum(TimelineType),
  tasks: z.array(
    z.object({
      id: z.string(),
      assignedDate: z.string().transform(stringToDateConvertor),
    })
  ),
});

export const ConvertingDontKnowMyWeddingDateToKnowMyWeddingDateRequest =
  z.discriminatedUnion("weddingFlowConversionRoute", [
    ArchiveAndStartFresh,
    ManuallyAdjustDate,
  ]);
