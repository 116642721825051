import { initContract } from "@ts-rest/core";
import { SuccessSchema } from "contract/common";
import {
  AddKeyMomentsRequest,
  CreateCommentRequest,
  CreateNewTaskCategoryRequest,
  CreateNewTaskRequest,
  CreateNewTimelineRequest,
  CreateNewTimelineResponse,
  DeleteCommentRequest,
  DeleteTaskCategoryRequest,
  DeleteTaskRequest,
  DeleteTimelineRequest,
  EditKeyMomentsRequest,
  EditTaskCategoryRequest,
  EditTaskRequest,
  EditTimelineRequest,
  GetAllCategoriesRequest,
  GetAllCategoriesResponse,
  GetAllTimelineRequest,
  GetAllTimelineResponse,
  GetCommentsRequest,
  GetCommentsResponse,
  GetGridTimelineRequest,
  GetGridTimelineResponse,
  GetKeyMomentsResponse,
  GetTaskEventDetailRequest,
  GetTaskEventDetailResponse,
  GetTimelineDataRequest,
  GetTimelineDataResponse,
  MoveTaskRequest,
  resetTimelineRequest,
} from "./types";
import { createPaginatedResponseSchema } from "contract/utils";
import { z } from "zod";
import { TimelineType } from "contract/enum";

const c = initContract();

export const timelineContract = c.router(
  {
    createNewTask: {
      method: "POST",
      path: "/task",
      responses: {
        200: SuccessSchema,
      },
      body: CreateNewTaskRequest,
    },
    createNewTaskCategory: {
      method: "POST",
      path: "/category",
      responses: {
        200: SuccessSchema,
      },
      body: CreateNewTaskCategoryRequest,
    },
    createNewTimeline: {
      method: "POST",
      path: "/",
      responses: {
        200: CreateNewTimelineResponse,
      },
      body: CreateNewTimelineRequest,
    },
    getTimelineData: {
      method: "GET",
      path: "/",
      responses: {
        200: GetTimelineDataResponse,
      },
      query: GetTimelineDataRequest,
    },
    getAllTimeline: {
      method: "GET",
      path: "/all",
      responses: {
        200: GetAllTimelineResponse,
      },
      query: GetAllTimelineRequest,
    },
    getAllTaskCategory: {
      method: "GET",
      path: "/category",
      responses: {
        200: GetAllCategoriesResponse,
      },
      query: GetAllCategoriesRequest,
    },
    moveTask: {
      method: "PATCH",
      path: "/task/move",
      responses: {
        200: SuccessSchema,
      },
      body: MoveTaskRequest,
    },
    editTask: {
      method: "PATCH",
      path: "/task",
      responses: {
        200: SuccessSchema,
      },
      body: EditTaskRequest,
    },
    editTaskCategory: {
      method: "PATCH",
      path: "/category",
      responses: {
        200: SuccessSchema,
      },
      body: EditTaskCategoryRequest,
    },
    editTimeline: {
      method: "PATCH",
      path: "/",
      responses: {
        200: SuccessSchema,
      },
      body: EditTimelineRequest,
    },
    deleteTask: {
      method: "DELETE",
      path: "/task",
      responses: {
        200: SuccessSchema,
      },
      body: DeleteTaskRequest,
    },
    deleteTaskCategory: {
      method: "DELETE",
      path: "/category",
      responses: {
        200: SuccessSchema,
      },
      body: DeleteTaskCategoryRequest,
    },
    deleteTimeline: {
      method: "DELETE",
      path: "/",
      responses: {
        200: SuccessSchema,
      },
      body: DeleteTimelineRequest,
    },
    resetTimeline: {
      method: "POST",
      path: "/reset",
      responses: {
        200: SuccessSchema,
      },
      body: resetTimelineRequest,
    },
    getGridView: {
      method: "GET",
      path: "/grid",
      responses: {
        200: GetGridTimelineResponse,
      },
      query: GetGridTimelineRequest,
    },
    getTaskEventDetail: {
      method: "GET",
      path: "/task_detail",
      responses: {
        200: GetTaskEventDetailResponse,
      },
      query: GetTaskEventDetailRequest,
    },
    createComment: {
      method: "POST",
      path: "/comment",
      responses: {
        200: SuccessSchema,
      },
      body: CreateCommentRequest,
    },
    getComments: {
      method: "GET",
      path: "/comment",
      responses: {
        200: createPaginatedResponseSchema(GetCommentsResponse),
      },
      query: GetCommentsRequest,
    },
    deleteComment: {
      method: "DELETE",
      path: "/comment",
      responses: {
        200: SuccessSchema,
      },
      body: DeleteCommentRequest,
    },
    getKeyMoments: {
      method: "GET",
      path: "/keyMoments",
      responses: {
        200: createPaginatedResponseSchema(GetKeyMomentsResponse),
      },
      query: z.object({
        projectId: z.string(),
        pageNumber: z.coerce.number().min(1),
        pageSize: z.coerce.number().min(1),
      }),
    },
    createKeyMoments: {
      method: "POST",
      path: "/keyMoments",
      responses: {
        200: SuccessSchema.extend({ keyMomentId: z.string() }),
      },
      body: AddKeyMomentsRequest,
    },
    editKeyMoments: {
      method: "PATCH",
      path: "/keyMoments",
      responses: {
        200: SuccessSchema,
      },
      body: EditKeyMomentsRequest,
    },
    isArchivedDataPresent: {
      method: "GET",
      path: "/isArchivedDataPresent",
      responses: {
        200: z.object({
          isPresent: z.boolean(),
          timelinesInfo: z.array(
            z.object({
              timelineName: z.string(),
              timelineType: z.nativeEnum(TimelineType).nullable(),
            })
          ),
        }),
      },
      query: z.object({
        projectId: z.string(),
      }),
    },

    getLatestTimeline: {
      method: "GET",
      path: "/latest",
      responses: {
        200: z.object({
          timelineType: z.nativeEnum(TimelineType),
        }),
      },
      query: z.object({
        projectId: z.string(),
      }),
    },
  },
  {
    pathPrefix: "/timeline",
  }
);
