import { initContract } from "@ts-rest/core";
import { SuccessSchema } from "contract/common";
import {
  AddBudgetTaskRequest,
  DeleteBudgetTaskRequest,
  GetTaskCategoryRequest,
  GetTaskCategoryWithTasksResponse,
  moveTaskRequest,
  UpdateBudgetRequest,
  UpdateBudgetTaskCategoryRequest,
  UpdateBudgetTaskRequest,
} from "./types";

const c = initContract();

export const budgetContract = c.router(
  {
    addTaskBudget: {
      method: "POST",
      path: "/task",
      responses: {
        200: SuccessSchema,
      },
      body: AddBudgetTaskRequest,
    },
    updateBudgetTask: {
      method: "PATCH",
      path: "/task",
      responses: {
        200: SuccessSchema,
      },
      body: UpdateBudgetTaskRequest,
    },
    deleteBudgetTask: {
      method: "DELETE",
      path: "/task",
      responses: {
        200: SuccessSchema,
      },
      body: DeleteBudgetTaskRequest,
    },
    updateBudgetTaskCategory: {
      method: "POST",
      path: "/task/category",
      responses: {
        200: SuccessSchema,
      },
      body: UpdateBudgetTaskCategoryRequest,
    },
    getBudgetTaskCategoryWithTasks: {
      method: "GET",
      path: "/task/categoryWithTasks",
      responses: {
        200: GetTaskCategoryWithTasksResponse,
      },
      query: GetTaskCategoryRequest,
    },
    updateBudget: {
      method: "PUT",
      path: "/",
      responses: {
        200: SuccessSchema,
      },
      body: UpdateBudgetRequest,
    },
    moveTask: {
      method: "PATCH",
      path: "/task/move",
      responses: {
        200: SuccessSchema,
      },
      body: moveTaskRequest,
    },
  },
  {
    pathPrefix: "/budget",
  }
);
